import {onFind} from "./elements-packages/init-modules-in-scope";
import {registerPermissionGrantedCallback} from "@elements/cookie-permissions";
import {setAttribute} from "@elements/dom-utils";


const selectors = {
    iframe: '.js-load-iframe-with-consent'
};

export function init () {
    onFind(selectors.iframe, function (baseElement) {
        let cookieService = baseElement.dataset.loadIframeWithConsent || false;

        if(cookieService) {
            registerPermissionGrantedCallback(cookieService, function () {
                setAttribute('src', baseElement.dataset.src, baseElement);
            });
        } else {
            console.warn('load-iframe-with-consent: no cookie service name found!')
        }
    });
}
