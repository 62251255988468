import {find, findIn, addClass, removeClass, on} from "@elements/dom-utils"
import {onFind} from "@elements/init-modules-in-scope";
import {createContentVisibility} from "js/scripts/content-visibility";

export function init () {
    let hasReopened = false;

    setTimeout(function (){


    onFind(".js-info-box", function (infoBox) {
        let closeBtn = findIn(".js-close-info-box", infoBox);
        let modalInstance = infoBox.dataset.instance;

        if(!modalInstance || modalInstance == 'undefined') {
            console.warn('dataset not set');
            return;
        }

        if (!sessionStorage.getItem('info' + modalInstance)) {
            addClass("show", infoBox);
        }

        if(closeBtn) {

            on('click', function () {
                sessionStorage.setItem('info' + modalInstance, 'closed');
                removeClass("show", infoBox);

                // time out for 2 minutes
                if(!hasReopened){
                setTimeout(function (){
                    sessionStorage.setItem('info' + modalInstance, 'open');
                    addClass("show", infoBox);
                },120000);
                hasReopened= true;
            }
            }, closeBtn);
        }

    });
    },10000);

}