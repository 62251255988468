import {on} from "@elements/dom-utils";

export function init() {
    // accessiway is a necessary cookie
    on('cf_init', () => {
        CookieFirst.consent && CookieFirst.consent.necessary && cb();
    }, window);

    on('cf_consent', (event) => {
        event.detail.necessary && cb();
    }, window);

    function cb() {
        var s = document.createElement('script'),
            e = !document.body ? document.querySelector('head') :
                document.body;
        s.src = 'https://eu.acsbapp.com/apps/app/dist/js/app.js';
        s.async = true;
        s.onload = function () {
            acsbJS.init({
                statementLink: '',
                footerHtml: '<a  href="https://www.accessiway.com/" target="_blank">AccessiWay. The Web Accessibility Solution</a>',
                hideMobile: false,
                hideTrigger: false,
                language: 'de',
                position: 'left',
                leadColor: '#d34442',
                triggerColor: '#d34442',
                triggerRadius: '3.3125rem',
                triggerPositionX: 'left',
                triggerPositionY: 'bottom',
                triggerIcon: 'people',
                triggerSize: 'medium',
                triggerOffsetX: 16,
                triggerOffsetY: 70,
                mobile: {
                    triggerSize: 'medium',
                    triggerPositionX: 'left',
                    triggerPositionY: 'bottom',
                    triggerOffsetX: 16,
                    triggerOffsetY: 70,
                    triggerRadius: '3.3125rem'
                }
            });
        };
        e.appendChild(s);
    }
}