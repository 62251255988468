import {onFind} from "@elements/init-modules-in-scope";
import {find, findIn, addClass, findAllIn, on, hasClass, toggleClass, findAll} from '@elements/dom-utils';

export function init() {

    let modal = find('.js-nav__overlay');

    onFind(".js-nav__toggle-overlay", function (search) {
        on('click', function() {
                onFind('.js-search__input', function (input) {
                  setTimeout(function () {
                      input.focus();
                  },100);
                })
        }, search);

    })
}
