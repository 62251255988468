"use strict";

import {onFind} from "@elements/init-modules-in-scope";
import {on, findIn} from '@elements/dom-utils';

export function init() {
    onFind('.js-cookiefirst-iframe', function (baseElement) {
        if (baseElement) {
            let category = baseElement.dataset.cookiefirstCategory;
            let dataSrc = baseElement.getAttribute('data-src');

            let timeout = setInterval(function () {
                if (typeof CookieFirst !== 'undefined') {
                    const consent = CookieFirst.consent;
                    if (consent && consent[category] === true) {
                        baseElement.setAttribute("src", dataSrc);
                        baseElement.removeAttribute("data-src");

                        const iframeOverlay = findIn('.js-cookiefirst-iframe-overlay', baseElement.parentElement);
                        iframeOverlay && iframeOverlay.classList.add('iframe-overlay__icon');
                        iframeOverlay && iframeOverlay.firstElementChild.classList.remove('d-none');
                        clearInterval(timeout);
                    }
                }
            }, 100);
        }
    });

    onFind('.js-cookie-first__accept-category', function (consentAcceptButton) {
        on('click', function (e) {
            e.preventDefault();
            let category = consentAcceptButton.dataset.category;
            CookieFirst.acceptCategory(category);
        }, consentAcceptButton);
    })
}
