import {findAllIn, off, on, trigger} from '@elements/dom-utils'

export function initInScope(scope = document.body) {
    trigger('initialize-in-scope', scope);
    trigger('initialized-in-scope', scope);
}

export function onInitInScope(callbackFnc, scope = document.body) {
    let boundCallbackFnc = (evt) => callbackFnc(evt.target);

    on('initialize-in-scope', boundCallbackFnc, scope);

    return () => off('initialize-in-scope', boundCallbackFnc, scope);
}

export function cleanUpInScope (scope = document.body) {
    trigger('clean-up-in-scope', scope);
    trigger('cleaned-up-in-scope', scope);
}

// todo does scope parameter make sense?
export function onCleanUpInScope(callbackFnc, scope = document.body) {
    let boundCallbackFnc = (evt) => callbackFnc(evt.target);

    on('clean-up-in-scope', boundCallbackFnc, scope);

    return () => off('clean-up-in-scope', boundCallbackFnc, scope);
}

export default initInScope;

export function onFind(selector, init, scope = document.body) {
    let cleanUpFunctions = [];
    // add callback
    onInitInScope(initInScope, scope);

    // call immediately
    initInScope(scope);


    onCleanUpInScope(function (scope) {
        cleanUpFunctions = cleanUpFunctions
            .filter(({element, cleanUpFunction}) => {
                if (scope.contains(element)) {
                    cleanUpFunction(element);
                    return false;
                }

                return true;
            });
    });

    function initInScope(scope) {
        findAllIn(selector, scope).map(element => {
            let result = init(element);

            if (!!result && typeof result === 'function') {
                cleanUpFunctions.push({
                    element,
                    cleanUpFunction: result
                });
            }

            if (!!result && result.cleanUp && typeof result.cleanUp === 'function') {
                cleanUpFunctions.push({
                    element,
                    cleanUpFunction: result.cleanUp
                });
            }
        });
    }
}

