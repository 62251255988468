"use strict";

import {findIn,find,on,addClass, trigger} from "@elements/dom-utils";
import {onFind} from "@elements/init-modules-in-scope";
import {forceRecalculation} from "@elements/affix";

export function init () {
    const isMobile = () => matchMedia('(max-width: 767px)').matches;

    onFind('.js-message', function (message) {
        let messageHeight = message.offsetHeight;
        let affixNavbarPlaceholder = find('.js-affix-navbar__placeholder');

        /* change affix offset if message is displayed */
        let affixElement = find('.js-affix');
        let affixOffset = 0;

        if(affixElement) {
            affixOffset = +affixElement.getAttribute('data-affix-offset') || 0;
            affixElement.setAttribute('data-affix-offset', affixOffset + messageHeight);
            forceRecalculation();
        }

        let messageClose = findIn('.js-message__close', message);

        const messageCloseHandler = (evt) => {
            console.log("message close clicked");
            document.cookie = "message-closed=true; expires='';";
            addClass('d-none', message);
            affixNavbarPlaceholder.style.minHeight = "0px";

            /* reset affix offset if message closed */
            if(affixElement) {
                affixElement.setAttribute('data-affix-offset', affixOffset);
                trigger('resize', window);
            }
        };

        on('click', (evt) => messageCloseHandler(evt), messageClose)

    });

}