import {onFind} from "@elements/init-modules-in-scope";
import {on, findIn} from "@elements/dom-utils";

export function init () {
    onFind('.js-scroll-to-right', function (element) {
        let scrollToRightButton = findIn('.js-scroll-to-right__button', element);
        if (scrollToRightButton) {
            on('click', function () {
                let scrollRightButtonsList = findIn('.js-scroll-to-right__list', element);
                scrollRight(scrollRightButtonsList);
            }, scrollToRightButton);
        }
    });
}

export function scrollRight(element) {
    element.scrollBy({
        left: 200,
        behavior: 'smooth'
    });
}